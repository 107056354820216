import Card from "atoms/Card";
import ImageNext from "atoms/ImageNext";
import Text from "atoms/Text";
import IconAddMeeting from "atoms/SvgIcons/HompagePatientIcons/IconAddMeeting.svg";
import IconVideo from "atoms/SvgIcons/IconVideo.svg";
import IconStar from "atoms/SvgIcons/HompagePatientIcons/IconStar.svg";
import { getAuth } from "services/identity.service";
const moment = require('moment');
import appointment_flow from "../assets/mo_engage/appointment_flow.json"
import { captureEvent } from 'services/analytics.service';
import IconLocation from "atoms/SvgIcons/IconLocation.svg";
import IconGraph from "atoms/SvgIcons/IconGraph.svg";
import useWindowSize from "customHook/useWindowSize";
import IconWallet from "atoms/SvgIcons/IconWallet.svg";
import { useState } from "react";
import { getDoctorAppointmentSlots } from "services/user.service";
import { DOCTOR_CONSULTATION_MODE } from "constant";

import { useRouter } from "next/router";
import Button from "atoms/Button";
import IconWalletCLinic from "atoms/SvgIcons/IconWalletClinic.svg";
import { useUserContext } from "@/components/context/UserContext";

const DoctorsSummaryCardV3 = ({ className, image, fullName, city, degree, experience, specializations, buttonText, linkHref, consultationFee, rating, isShowVideoIcon = false, nextAvailableSlots = [], isCurated = false, pageName = '', doctorId = null, screen, isListingPage = false, profileViews, recentlyConsulted, availableInClinic, clinicId = null, clinicFee, preferredLanguages }) => {
    const auth = getAuth();
    const isConsultationAllowed = auth?.user?.userType == 1 || auth?.user?.userType == undefined;
    const eventCategory = pageName;
    const [selectedSlotTime, setSelectedSlotTime] = useState(null)
    const [showBookAppointmentModal, setBookAppointmentModal] = useState(false);

    const eventName = appointment_flow?.entity?.Request_Appointment_Click?.event_name;
    const [width] = useWindowSize();
    const [doctorSlots, setDoctorSlots] = useState(null)
    const [isSlotLoad, setIsSlotLoad] = useState(true)
    const [consultationMode, setConsultationMode] = useState(DOCTOR_CONSULTATION_MODE?.VIDEO_CONSULTATION);
    const [isSlotSelected, setIsSlotSelected] = useState(false)

    const specializationArray = typeof specializations === 'string' ? specializations.split(',') : specializations;
    const [expandedDoctorId, setExpandedDoctorId] = useState(null);
    const router = useRouter();
    const { setConsultationType } = useUserContext();

    async function getDoctorSlots({ doctorId, type }) {
        setIsSlotLoad(true);
        const slotResp = await getDoctorAppointmentSlots({
            doctorId,
            type,
            ...(type == 1 ? { clinicId: clinicId } : {}),
        });
        if (slotResp?.status) {
            setDoctorSlots(slotResp?.entity)
            setIsSlotLoad(false)
        }
    }

    const handleCardExpansion = async ({ doctorId, type }) => {
        setExpandedDoctorId(expandedDoctorId === doctorId ? null : doctorId);
        await getDoctorSlots({ doctorId, type })
        setConsultationMode(type)
    };

    const showBookAppointmentFormModal = async (time) => {
        setSelectedSlotTime(time);
        setBookAppointmentModal(true);
        setIsSlotSelected(false);
    }
    const handleBookAppointment = (e) => {
        if (selectedSlotTime) {
            setBookAppointmentModal(true);
            setIsSlotSelected(false);
        } else {
            setIsSlotSelected(true);
        }
    }

    return (
        <Card className={`${className} p-4 cursor-pointer hover:shadow-extraLight transition-all duration-300 card--regular relative flex flex-col rounded-md shadow-sm w-full md:w-[414px] h-[410px]`}>
            <div className={`flex-grow`}>
                <div className="flex flex-col w-full" >
                    <div className="relative flex items-center justify-between w-full">
                        <div className="flex items-start w-full" onClick={() => { router.push(linkHref) }}>
                            <div className="flex flex-col items-center" >
                                <div className={`relative min-w-[120px] h-[120px] rounded-full bg-gray-100`}>
                                    <ImageNext
                                        className="w-full h-full object-cover rounded-full"
                                        src={image}
                                        alt={fullName}
                                        layout="fill"
                                        defaultImageFor="avatar"
                                    />
                                    {isConsultationAllowed && isCurated && nextAvailableSlots?.length > 0 && isShowVideoIcon ? (
                                        <div className="absolute bottom-1 right-1 flex items-center justify-center h-7 w-7 rounded-full bg-blue-500 text-white overflow-x-visible">
                                            <IconAddMeeting className="h-7" />
                                        </div>
                                    ) : null}
                                </div>

                                {profileViews != 0 &&
                                    <div className="flex items-center space-x-1 mt-2">
                                        <IconGraph />
                                        <Text className="text-xs text-gray-600 font-semibold">{profileViews} Views</Text>
                                    </div>
                                }

                                <Text className={`flex items-center pt-1.5 gap-1 text-xs text-gray-900`}>
                                    <img className='w-4 h-4' src="/images/icon_success.svg" alt='verified_tick' />
                                    Verified Doctor
                                </Text>

                            </div>

                            <div className="ml-3 flex-grow w-full">
                                <div className="flex flex-col justify-between gap-1">
                                    <Text className={`font-Open-Sans font-semibold text-gray-900 text-lg}`}>{fullName || ""}</Text>

                                    {isConsultationAllowed &&
                                        <div className="flex gap-1.5">
                                            <IconStar className="text-yellow-400 w-4 h-4" />
                                            <Text className="text-sm font-Open-Sans text-gray-900">{((!rating || rating < 1)) ? 'New' : (rating) + '/5'}</Text>
                                        </div>
                                    }
                                </div>

                                <Text fontWeight={400} className={`text-gray-600 pt-1.25 pb-1 text-sm leading-[19px] line-clamp-2`}>
                                    {Array.isArray(specializations) ? specializations.join(', ') : specializations}
                                </Text>

                                <Text className={`text-gray-600 text-sm`}>
                                    {experience || ""}
                                </Text>

                                <Text className={`text-sm pt-1.25 pb-1 text-gray-600 line-clamp-1`}>
                                    {degree || "degree"}
                                </Text>

                                <Text className="text-sm text-gray-600">
                                    {preferredLanguages?.join(', ')}
                                </Text>

                            </div>
                        </div>
                    </div>


                    <div>
                        <div className={`flex gap-1 items-center border-t border-x-0 mt-2.5 mb-1.5 pt-2`}>
                            <IconLocation className='text-gray-600' size={16} />
                            <Text className={`text-sm text-gray-900 font-Open-Sans`}>
                                {city?.split(', ')?.map(part => part.toLowerCase()?.split(' ')?.map(word => word.charAt(0).toUpperCase() + word.slice(1))?.join(' ')).join(', ') || ""}</Text>
                        </div>
                        {
                            isConsultationAllowed &&
                            <div className="flex justify-between items-center">
                                {(isCurated == 1 || availableInClinic == 1) && isConsultationAllowed &&
                                    <div className="flex items-center">
                                        <Text className='text-sm text-gray-900 mr-1 md:mr-3'>
                                            Consultation Fee
                                        </Text>
                                            <div className='flex justify-between gap-3'>
                                                {
                                                    availableInClinic == 1 &&
                                                    <div className="flex">
                                                        <IconWalletCLinic />
                                                        <Text className={`font-semibold mx-1 md:mr-1 md:ml-2 text-sm }`}>
                                                            {clinicFee ? `₹${clinicFee}` : 'FREE at clinic'}
                                                            {clinicFee ? '  at clinic' : ''}
                                                        </Text>
                                                    </div>
                                                }
                                                {
                                                    isCurated == 1 &&
                                                    <div className="flex">
                                                        <IconWallet />
                                                        <Text className={`font-semibold mx-1 md:mr-1 md:ml-2 text-sm }`}>
                                                            {consultationFee ? `₹${consultationFee}` : 'FREE online'}
                                                            {consultationFee ? '  online' : ''}
                                                        </Text>
                                                    </div>
                                                }
                                            </div>
                                    </div>}
                            </div>
                        }

                        {recentlyConsulted &&
                            <div className="mt-1.5 pb-2 border-b">
                                <Text className='text-sm font-semibold text-gray-900 font-Open-Sans'>Recently consulted for : <span className="font-normal">{recentlyConsulted}</span></Text>
                            </div>
                        }


                        {screen != "doctorListing" && isConsultationAllowed && isCurated == 1 && nextAvailableSlots?.length >= 1 &&
                            <div className="text-gray-600 mt-2">
                                <Text type="label" className="text-sm font-Open-Sans text-semantic-success_base mb-1">Next available at</Text>
                                <div className={`flex ${width < 430 && 'flex-wrap'} justify-between`}>
                                    <div className="flex items-center">
                                        <IconVideo className="w-3.5 h-3.5 mr-2" />
                                        {nextAvailableSlots[0]?.label && <Text className='text-sm text-gray-600 font-Open-Sans'>{nextAvailableSlots[0]?.label}, {moment(nextAvailableSlots[0].value, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}</Text>}
                                    </div>
                                    {nextAvailableSlots[1]?.label && <div className="flex items-center mr-2">
                                        <IconVideo className="w-3.5 h-3.5 mr-2" />
                                        <Text className='text-sm text-gray-600 font-Open-Sans'>{nextAvailableSlots[1]?.label}, {moment(nextAvailableSlots[1].value, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}</Text>
                                    </div>}
                                </div>
                            </div>}
                        {
                            (screen != 'doctorListing' && isConsultationAllowed && (isCurated == 0 || nextAvailableSlots?.length == 0)) && <Text className='w-3/4 text-center mx-auto py-6 text-primary1-900 text-xs'>{`${fullName} is not available for online consultation at the moment.`}</Text>
                        }
                    </div>

                    {isConsultationAllowed && isCurated == 1 && nextAvailableSlots?.length >= 1 && (
                        <div className={`flex justify-center mt-3 gap-3 w-full`}>
                            {availableInClinic == true &&
                                <Button
                                    size='extraSmall_v4'
                                    variant="Ghost"
                                    fontWeight={400}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (isListingPage == true && width > 450) {
                                            handleCardExpansion({ doctorId, type: DOCTOR_CONSULTATION_MODE?.IN_CLINIC_CONSULTATION });
                                        }
                                        else {
                                            setConsultationType(1);
                                            router.push(linkHref)
                                        }
                                    }}
                                    fontSize={"bodySmall"}
                                    className={`min-w-[182px] h-9 text-sm font-semibold  button button-Primary rounded-sm-0.5`}
                                >
                                    {buttonText || "Book Clinic Visit"}
                                </Button>
                            }

                            <Button
                                size='extraSmall_v4'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    captureEvent(eventCategory, eventName, '', { doctor_id: doctorId, doctor_name: fullName, action: 'Click' });
                                    if (isListingPage == true && width > 450) {
                                        handleCardExpansion({ doctorId, type: DOCTOR_CONSULTATION_MODE?.VIDEO_CONSULTATION });
                                    }
                                    else {
                                        setConsultationType(0);
                                        router.push(linkHref)
                                    }
                                }}
                                className={`${availableInClinic == 1 ? 'min-w-[182px]' : 'min-w-[210px]'} h-9 text-sm font-semibold button button-Primary rounded-sm-0.5`}
                            >
                                {availableInClinic == 1 ? "Video Consultation" : "Book Video Consultation"}
                            </Button>
                        </div>
                    )}

                </div>
            </div>
        </Card >
    )
};

export default DoctorsSummaryCardV3;
