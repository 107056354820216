import React, { useState } from 'react';

import Text from 'atoms/Text';
import Container from 'atoms/Container';

import HorizontalSliderV2 from 'molecules/HorizontalSliderV2';
import DoctorsListCore from './TopDoctorListCore';
import { TOP_ICON_HOMEPAGE_BREAKPOINTS, TOP_HEADER_ICONS } from 'constant/patientHomepage';
import { captureEvent } from 'services/analytics.service';
import homepage_patient from "../../assets/mo_engage/homepage_patient.json"

const TopIconWrapper = ({limit=29, city="", isShowApptForm=true}) => {
    const [selectedIcon, setSelectedIcon] = useState(0);
    const [selectedSpecialization, setSelectedSpecialization] = useState('');
    const [page, setPage] = useState(1);
    const eventCategory = homepage_patient?.category;
    const eventName = homepage_patient?.entity?.specilality_tab?.event_name;

    const handleIconSelect = (index, specialization) => {
        setSelectedIcon(index);
        setSelectedSpecialization(specialization);
        captureEvent(eventCategory, eventName, specialization, {});
    };

    return (
        <div className="mx-auto pt-1 ">
            <Container type={'static'} className="relative" >
                <HorizontalSliderV2
                    className="w-auto h-full border-b-gray-300 border-b-[1px] "
                    title=""
                    navigationElement="menuslider"
                    sliderList={TOP_HEADER_ICONS.map((icon, index) => (
                        <div
                            key={index}
                            onClick={() => handleIconSelect(index, icon.specialization)}
                            className={`cursor-pointer flex flex-col items-center h-full px-2 w-20 ml-0   border-0`}
                        >
                            <div className="w-[30px] h-[30px] flex items-center justify-center ">
                                {selectedIcon === index
                                    ? React.createElement(icon.activeComponent)
                                    : React.createElement(icon.component)}
                            </div>
                            <Text className={`text-center  mt-2 w-[105px] text-12-16 ${(selectedIcon == index) ? 'font-semibold font-Open-Sans text-primary1-500' : 'font-Open-Sans'}`}>{icon.name}</Text>
                            {selectedIcon === index && (
                                <div className="mx-0 w-19 h-1 bg-primary1-500 border-0 rounded absolute -bottom-2 md:-bottom-4" >
                                </div>
                            )}
                        </div>
                    ))}
                    breakpoints={TOP_ICON_HOMEPAGE_BREAKPOINTS}
                    spaceBetween={35}
                    isShowArrow={true}
                />


            </Container>

            <Container type="static" className="relative pt-5 pb-7">
                <DoctorsListCore
                    page={page}
                    setPage={setPage}
                    limit={limit}
                    city={city}
                    search={''}
                    isSortBySearch={false}
                    selectedSpecialization={selectedSpecialization}
                    isShowApptForm={isShowApptForm}
                />
            </Container>
        </div>
    );
};

export default TopIconWrapper;
